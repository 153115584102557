<template>
    <div class="cohorts-lists">
        <el-table :data="cohortsList" style="width: 100%">
          <el-table-column label="Cohort #" width="150">
            <template #default="scope">
                <div class="flex-row ai-c" style="gap: .5rem;" @click="goToCohortInvoicePage">
                  <p class="cohorts-lists__data default-text">{{ scope.row.cohortNumber }}</p>
                  <el-icon :size="12" color="#0C0F4A"><ArrowRightBold/></el-icon>
                </div>
            </template>
          </el-table-column>
          <el-table-column label="Course name">
            <template #default="scope">
                <p class="cohorts-lists__data default-text" @click="goToCohortInvoicePage">{{ scope.row.courseName }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Start date" width="130">
            <template #default="scope">
                <p class="cohorts-lists__data default-text" @click="goToCohortInvoicePage">{{ scope.row.startDate }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Start time" width="130">
            <template #default="scope">
                <p class="cohorts-lists__data default-text" @click="goToCohortInvoicePage">{{ scope.row.startTime }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Duration" width="130">
            <template #default="scope">
                <p class="cohorts-lists__data default-text" @click="goToCohortInvoicePage">{{ scope.row.duration }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Learners" width="120">
            <template #default="scope">
                <p class="cohorts-lists__data default-text" @click="goToCohortInvoicePage">{{ scope.row.learners }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Lead tutor" width="150">
            <template #default="scope">
                <p class="cohorts-lists__data default-text" @click="goToCohortInvoicePage">{{ scope.row.leadTutor }}</p>
            </template>
          </el-table-column>
      </el-table>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';

export default {
  data() {
    return {
      cohortsList: [
        {
          cohortNumber: 'Cohort 1',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
        {
          cohortNumber: 'Cohort 2',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
        {
          cohortNumber: 'Cohort 3',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
        {
          cohortNumber: 'Cohort 4',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
        {
          cohortNumber: 'Cohort 5',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
        {
          cohortNumber: 'Cohort 6',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
        {
          cohortNumber: 'Cohort 7',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
        {
          cohortNumber: 'Cohort 8',
          courseName: 'L3 Air Source Heat Pump',
          startDate: '17/10/2022',
          startTime: '09:00 AM',
          duration: '4 Days',
          learners: '11 / 12',
          leadTutor: 'Sally Dudley ',
        },
      ]
    };
  },
  components: {
    ArrowRightBold
  },
  methods: {
    goToCohortInvoicePage() {
      this.$router.push('/cohort-invoice');
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
}
.bold-text {
    font-weight: 700;
}
.name-container {
  width: 90%;
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.el-table {
  --el-table-tr-bg-color: transparent;
  --el-table-bg-color: transparent;
  --el-table-header-bg-color: transparent;
}
:deep(.el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}
:deep(.el-table__row) {
  cursor: pointer;
}
:deep(.cell) {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #0c0f4a;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  font-family: Mulish;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.cohorts-lists {
}

@include media-xs-max-width() {
  .default-text {
    font-size: 12px;
  }
  :deep(.cell) {
    font-size: 12px;
  }
}
</style>
