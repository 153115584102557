<template>
    <div class="cohorts-list flex-column">
        <CohortsHeaderComponent/>
        <div class="cohort-list__wrapper flex-column">
           <CohortsListHeaderComponent :isFilterHeader="true"/>
           <TrainingProviderCohortsList v-if="isProjectOwner"/>
           <AwardPartnerCohortsList v-if="isTradesperson"/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import AwardPartnerCohortsList from '@/modules/cohorts/components/AwardPartnerCohortsList.vue';
import CohortsListHeaderComponent from '@/modules/cohorts/components/CohortsListHeaderComponent.vue';
import TrainingProviderCohortsList from '@/modules/cohorts/components/TrainingProviderCohortsList.vue';
import CohortsHeaderComponent from '@/modules/dashboard/components/cohort-components/CohortsHeaderComponent.vue';

export default defineComponent({
  components: {
    CohortsHeaderComponent,
    CohortsListHeaderComponent,
    AwardPartnerCohortsList,
    TrainingProviderCohortsList
  },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  }
});
</script>
<style lang="scss" scoped>
.cohorts-list {
    max-width: 1230px;
    gap: 1.6rem;
    position: relative;
}
</style>
