<template>
    <div class="cohorts-lists">
        <el-table :data="cohortsList" style="width: 100%">
          <el-table-column label="Invoice #" width="150">
            <template #default="scope">
                <div class="flex-row ai-c" style="gap: .5rem;">
                  <p class="cohorts-lists__data default-text">{{ scope.row.invoiceNumber }}</p>
                  <el-icon :size="12" color="#0C0F4A"><ArrowRightBold/></el-icon>
                </div>
            </template>
          </el-table-column>
          <el-table-column label="Training provider">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.trainingProvider }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Course name">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.courseName }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Course date" width="130">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.courseDate }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Learners" width="120">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.learners }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Invoice sum" width="120">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.invoiceSum }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Pay" width="160">
            <template #default="scope">
                <div class="cohorts-lists__action-container flex-row ai-c jc-c">
                  <div class="flex-row ai-c jc-c" v-if="scope.row.isPaid">
                    <p class="cohorts-lists__paid-text default-text">PAID</p>
                    <inline-svg class="cohorts-lists__download-icon" width="15" height="15" :src="require('@/assets/icons/filled-download-icon.svg')"/>
                  </div>
                  <el-button type="primary" class="cohorts-lists__button flex-row ai-c jc-c"  @click="goToCohortInvoicePage" round :loading="submitting" v-if="!scope.row.isPaid">PAY INVOICE</el-button>
                </div>
            </template>
         </el-table-column>
      </el-table>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';

export default {
  data() {
    return {
      cohortsList: [
        {
          invoiceNumber: 'GT1',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: false,
        },
        {
          invoiceNumber: 'GT2',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: true,
        },
        {
          invoiceNumber: 'GT3',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: true,
        },
        {
          invoiceNumber: 'GT4',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: true,
        },
        {
          invoiceNumber: 'GT5',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: false,
        },
        {
          invoiceNumber: 'GT6',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: false,
        },
        {
          invoiceNumber: 'GT7',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: true,
        },
        {
          invoiceNumber: 'GT8',
          trainingProvider: 'Get Training',
          courseName: 'L3 Air Source Heat Pump',
          courseDate: '17/10/2022',
          learners: '12 / 12',
          invoiceSum: '£10,000.00',
          isPaid: true,
        },
      ]
    };
  },
  components: {
    ArrowRightBold
  },
  methods: {
    goToCohortInvoicePage() {
      this.$router.push('/cohort-invoice');
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
}
.bold-text {
    font-weight: 700;
}
.name-container {
  width: 90%;
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.el-table {
  --el-table-tr-bg-color: transparent;
  --el-table-bg-color: transparent;
  --el-table-header-bg-color: transparent;
}
:deep(.el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}
:deep(.el-table__row) {
  cursor: pointer;
}
:deep(.cell) {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #0c0f4a;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  font-family: Mulish;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.cohorts-lists {
  &__action-container {
    width: 100%;
    height: 42px;
    cursor: pointer;
  }
  &__paid-text {
    color: #FFA500;
  }
  &__button {
    height: 36px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    color: #4F55F0;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1);
    border-radius: 8px;
    border: none;
    outline: none;
    box-sizing: border-box;
  }
}

@include media-xs-max-width() {
  .default-text {
    font-size: 12px;
  }
  :deep(.cell) {
    font-size: 12px;
  }
}
</style>
